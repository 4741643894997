import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import { PostsForPageQuery, IGasbyPageProps, IPost } from '~/types'
import SEO from '~/components/SEO'
import PostPreview from '~/components/PostPreview'
import ArticleContainer from '~/components/ArticleContainer'
import Pagination from '~/components/Pagination'
import { formatPostListFromData } from '~/utils/post'
import FilterPanel from '~/components/FilterPanel'

interface IPostListByTagTemplatePageContext {
  currentPage: number
  numPages: number
  urlKey: string
}

interface IPostListByTagTemplateProps extends IGasbyPageProps {
  data: PostsForPageQuery
  pageContext: IPostListByTagTemplatePageContext
}

const PostListByTagTemplate: React.FC<IPostListByTagTemplateProps> = ({
  data,
  uri,
  pageContext,
}) => {
  const posts = formatPostListFromData(data)

  const { currentPage, numPages, urlKey } = pageContext
  return (
    <Layout uri={uri}>
      <ArticleContainer>
        <FilterPanel></FilterPanel>
        {posts.map((post, index) => (
          <PostPreview post={post} key={index} />
        ))}
        <Pagination
          className="fixed-width mb-2"
          currentPage={currentPage}
          numberOfPages={numPages}
          getPageUriByIndex={n =>
            n === 1 ? `/blog/tags/${urlKey}` : `/blog/tags/${urlKey}/page-${n}`
          }
        ></Pagination>
      </ArticleContainer>
      <SEO
        title="Блог"
        description="Проповеди, беседы, передачи, стихи, уроки, путевые заметки, аудио и видео материалы, прот. Артемия Владимирова. Официальный блог."
        robots="noindex,nofollow"
      ></SEO>
    </Layout>
  )
}

export default PostListByTagTemplate

export const query = graphql`
  query postsByTagForPage($skip: Int!, $limit: Int!, $contentWidth: Int!, $tag: [String]) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: { draft: { ne: true }, tags: { in: $tag } }
        fields: { urlPath: { ne: null }, source: { eq: "posts" } }
      }
    ) {
      edges {
        node {
          ...PostDataFragment
        }
      }
    }
  }
`
